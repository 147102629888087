import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';
import { bp } from '../../styles/mediaBreakpoints';
import {
  SKEW_CLIP_PATH_SIZE_EM,
  SKEW_CLIP_PATH_SIZE_PX,
} from '../../styles/variables';

const titleFontSizeEm = 1.5;

const Wrapper = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 3em;

  .card__title {
    width: 100%;
    text-align: center;
    font-size: ${titleFontSizeEm}em;
    font-weight: bold;
    padding: 1em 1em 1em;
    box-sizing: border-box;
  }
  .card__info {
    width: 100%;
    text-align: center;
    font-size: 1.1em;
    font-weight: 500;
    padding: 0.5em 2em;
    box-sizing: border-box;
  }
  .card__image {
    width: 100%;
    box-sizing: border-box;
  }
  .card__body {
    width: 100%;
    padding: 0.5em 2em 1em 2em;
    box-sizing: border-box;

    & > ul {
      & > li {
        list-style: disc;
        margin-bottom: 0.2em;
      }
    }
  }
  ${({ theme, infoChildren, hasShadow }) => {
    let imageWrapperBackground = theme.colors.brown35;
    if (infoChildren) {
      imageWrapperBackground = theme.colors.blue;
    }
    return css`
      .card__title {
        background-color: ${theme.colors.blue};
        color: white;
        overflow-wrap: break-word;
      }
      .card__info {
        background-color: ${theme.colors.brown35};
        color: white;
        overflow-wrap: break-word;
      }
      .card__image {
        background-color: ${imageWrapperBackground};
        border-top: 4px solid ${theme.colors.blue};
        border-bottom: 4px solid ${theme.colors.blue};
      }
      .card__body {
        background-color: ${theme.colors.blue70};
        overflow-wrap: break-word;
      }
    `;
  }}
  ${({ theme, infoChildren, hasShadow }) => {
    if (hasShadow) {
      return css`
        filter: drop-shadow(2px 2px 8px ${theme.colors.blue});
      `;
    }
  }}
  ${({ theme, image }) => {
    let imagePaddingBottomEM = 1.5;
    let bodyPaddingTopEM = 3;
    let bodyMarginTopEM = -2.5;
    if (image) {
      imagePaddingBottomEM = 0;
      bodyPaddingTopEM = 0.5;
      bodyMarginTopEM = 0;
    }
    return theme.mixins.useSkewed(
      css`
        .card__title {
          clip-path: polygon(
            0% ${SKEW_CLIP_PATH_SIZE_PX}px,
            ${SKEW_CLIP_PATH_SIZE_PX}px 0%,
            calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px) 0%,
            100% ${SKEW_CLIP_PATH_SIZE_PX}px,
            100% 100%,
            0% 100%
          );
        }
        .card__info {
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
        .card__body {
          clip-path: polygon(
            0% 0%,
            100% 0%,
            100% calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px),
            calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px) 100%,
            ${SKEW_CLIP_PATH_SIZE_PX}px 100%,
            0% calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px)
          );
        }
        @media (min-width: ${bp.md}px) {
          .card__title {
            clip-path: polygon(
              0% ${SKEW_CLIP_PATH_SIZE_PX}px,
              ${SKEW_CLIP_PATH_SIZE_PX}px 0%,
              calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px) 0%,
              100% ${SKEW_CLIP_PATH_SIZE_PX}px,
              100% calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px),
              0% 100%
            );
            padding-bottom: 1.5em;
            z-index: 2;
          }
          .card__info {
            clip-path: polygon(
              0% 0%,
              100% 0%,
              100% calc(100% - ${SKEW_CLIP_PATH_SIZE_PX}px),
              0% 100%
            );
            margin-top: -2.5em;
            padding-top: 3em;
            padding-bottom: 1.5em;
            z-index: 1;
          }
          .card__image {
            margin-top: -2.5em;
            padding-top: 3em;
            padding-bottom: ${imagePaddingBottomEM}em;
          }
          .card__body {
            padding-top: ${bodyPaddingTopEM}em;
            margin-top: ${bodyMarginTopEM}em;
          }
        }
      `
    );
  }}
`;

const CardTitle = styled.div``;

const Card = React.forwardRef(
  (
    {
      children,
      hasShadow,
      image,
      titleProps,
      infoProps = { children: null },
      ...rest
    },
    ref
  ) => {
    const {
      children: titleChildren,
      className: titleClassName,
      ...titleRest
    } = titleProps;
    const {
      children: infoChildren,
      className: infoClassName,
      ...infoRest
    } = infoProps;
    return (
      <Wrapper
        infoChildren={infoChildren}
        image={image}
        hasShadow={hasShadow}
        ref={ref}
        {...rest}
      >
        <CardTitle {...titleRest} className={`card__title ${titleClassName}`}>
          {titleChildren}
        </CardTitle>
        {infoProps && infoChildren && (
          <div className={`card__info ${infoClassName}`} {...infoRest}>
            {infoChildren}
          </div>
        )}
        {image && <div className="card__image">{image}</div>}
        <div className="card__body">{children}</div>
      </Wrapper>
    );
  }
);

export default Card;
