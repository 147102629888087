/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import styled, { css } from 'styled-components';
import { isHeader } from '../utils';

const availableTags = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export const Header = React.forwardRef(
  ({ type, children, as, ...props }, ref) => {
    return React.createElement(
      availableTags[type || 'h1'],
      { ref, ...props },
      children
    );
  }
);

const Wrapper = styled(Header)`
  ${({ as, type }) => {
    if (isHeader(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'], as, type)) {
      return css`
        margin-top: 1em;
        text-transform: uppercase;
      `;
    }
  }}
  ${({ as, type }) => {
    if (isHeader(['h1', 'h2'], as, type)) {
      return css`
        margin-top: 1.5em;
        margin-bottom: 0.7em;
      `;
    }
  }}
  ${({ as, type }) => {
    if (isHeader(['h3', 'h4'], as, type)) {
      return css`
        text-transform: uppercase;
        padding-top: 1em;
        margin-bottom: 0.3em;
      `;
    }
  }}
  ${({ as, type }) => {
    if (isHeader(['h5', 'h6'], as, type)) {
      return css`
        text-transform: uppercase;
        padding-top: 0.5em;
        margin-bottom: 0.3em;
      `;
    }
  }}
`;

export default Wrapper;
