import React, { useContext, useRef } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faCss3Alt,
  faReact,
  faDigitalOcean,
} from '@fortawesome/free-brands-svg-icons';
import { SiGatsby } from '@react-icons/all-files/si/SiGatsby';
import { SiNginx } from '@react-icons/all-files/si/SiNginx';
import { SiStyledComponents } from '@react-icons/all-files/si/SiStyledComponents';
import { SiStrapi } from '@react-icons/all-files/si/SiStrapi';
import SectionHeader from '../SectionHeader';
import Card from '../ui/Card';
import UnderlinedSpan from '../ui/UnderlinedSpan';
import { LazyLoadContext } from '../../context/lazyLoadedJs';
import useRevealAnimation from '../../hooks/useRevealAnimation';

const Wrapper = styled.section`
  ${({ theme, id }) => {
    return css`
      & > ul {
        padding: 0;
        margin: 0;
      }
      & > ul > li {
        list-style: none;
      }
      .${id}-projects__list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
      }
      .${id}-projects__item {
        margin-bottom: 3em;
        &:last-child {
          margin: 0;
        }
      }
      .card__info {
        a {
          color: white;
        }
      }
    `;
  }}
`;

const ProjectsSection = ({ data, id, location, wasVisited }) => {
  const [modules, moduleActions] = useContext(LazyLoadContext);

  const fadeInProps = {
    duration: 0.5,
    autoAlpha: 1,
  };
  const fadeInInitProps = {
    opacity: '0',
    transform: 'translate(0, 0)',
  };
  const slideInProps = {
    duration: 0.5,
    autoAlpha: 1,
    xPercent: 0,
    x: 0,
  };
  const slideInInitProps = {
    opacity: '0',
    transform: 'translateX(-50%)',
  };

  const sh1 = useRef();
  const [setSh1] = useRevealAnimation(
    sh1,
    [
      {
        targets: [sh1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh1P1 = useRef();
  const [setSh1P1] = useRevealAnimation(
    sh1P1,
    [
      {
        targets: [sh1P1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh1P2 = useRef();
  const [setSh1P2] = useRevealAnimation(
    sh1P2,
    [
      {
        targets: [sh1P2],
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1,
          ease: 'elastic.out(1, 0.5)',
        },
        initialStyle: {
          opacity: 0,
          transform: 'scale(0.8)',
        },
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh1P3 = useRef();
  const [setSh1P3] = useRevealAnimation(
    sh1P3,
    [
      {
        targets: [sh1P3],
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1,
          ease: 'elastic.out(1, 0.5)',
        },
        initialStyle: {
          opacity: 0,
          transform: 'scale(0.8)',
        },
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  return (
		<Wrapper className="container horizontal-pad" id={id}>
			<SectionHeader type="h2" id={`${id}__header`} to={`#${id}`} ref={setSh1}>
				Projects
			</SectionHeader>
			<p className="text-content section-description" ref={setSh1P1}>
				Selected projects I have done:
			</p>
			<ul className={`${id}-projects__list`}>
				<Card
					className={`${id}-projects__item`}
					as="li"
					hasShadow
					titleProps={{
						children: 'Portfolio page',
					}}
					image={<Img fluid={data.images.portfolio.childImageSharp.fluid} alt="" />}
					ref={setSh1P2}
				>
					<p className="text-content font-sans">The site you're currently at.</p>
					<p className="text-content font-sans">
						It is hosted on <UnderlinedSpan>DigitalOcean</UnderlinedSpan> <FA icon={faDigitalOcean} /> droplet server
						and served by <UnderlinedSpan>NGINX</UnderlinedSpan>{' '}
						<SiNginx className="react-icons-icon" aria-hidden="true" focusable="false" />.
					</p>
					<p className="text-content font-sans">
						On the front-end it uses <UnderlinedSpan>React</UnderlinedSpan> <FA icon={faReact} /> with{' '}
						<UnderlinedSpan>Gatsby</UnderlinedSpan>{' '}
						<SiGatsby className="react-icons-icon" aria-hidden="true" focusable="false" /> as the main building blocks.
						Styling is done with the help of React styling library - <UnderlinedSpan>styled-components</UnderlinedSpan>{' '}
						<SiStyledComponents className="react-icons-icon" aria-hidden="true" focusable="false" />. Animations are
						made with traditional <UnderlinedSpan>CSS</UnderlinedSpan> transitions <FA icon={faCss3Alt} /> and{' '}
						<UnderlinedSpan>GreenSock</UnderlinedSpan>{' '}
						<img alt="" className="inline-icon" src="gsap.svg" width="250" height="291" /> library and they respect
						user's reduced motion setting. SVG morphing is done with the help of{' '}
						<UnderlinedSpan>Kute.js</UnderlinedSpan> library.
					</p>
				</Card>
			</ul>
		</Wrapper>
	);
};

export default ProjectsSection;
