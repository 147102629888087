import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.span`
  ${({ theme, notchSizeEm, background }) => {
    return css`
      display: inline-block;
      position: relative;
      transition: filter 0.2s;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${background};
        transition: border-radius 0.2s;
        border-radius: ${notchSizeEm}em;
      }
    `;
  }}
  ${({ theme, notchSizeEm, background }) => {
    if (notchSizeEm > 0) {
      return theme.mixins.useSkewed(
        css`
          &:before {
            transition: clip-path 0.2s, border-radius 0.2s;
            clip-path: polygon(
              0% ${notchSizeEm}em,
              ${notchSizeEm}em 0%,
              calc(100% - ${notchSizeEm}em) 0%,
              100% ${notchSizeEm}em,
              100% calc(100% - ${notchSizeEm}em),
              calc(100% - ${notchSizeEm}em) 100%,
              ${notchSizeEm}em 100%,
              0% calc(100% - ${notchSizeEm}em)
            );
          }
        `
      );
    }
  }}
  ${({ theme, notchSizeEm, hasHover, background }) => {
    if (hasHover) {
      return css`
        &:hover {
          filter: drop-shadow(2px 2px 8px ${background});
          &:before {
            border-radius: ${notchSizeEm}em;
          }
        }
      `;
    }
  }}
  ${({ theme, notchSizeEm, hasHover, background }) => {
    if (hasHover) {
      const hoverNotchSize = 0;
      return theme.mixins.useSkewed(
        css`
          &:hover {
            &:before {
              clip-path: polygon(
                0% ${hoverNotchSize}em,
                ${hoverNotchSize}em 0%,
                calc(100% - ${hoverNotchSize}em) 0%,
                100% ${hoverNotchSize}em,
                100% calc(100% - ${hoverNotchSize}em),
                calc(100% - ${hoverNotchSize}em) 100%,
                ${hoverNotchSize}em 100%,
                0% calc(100% - ${hoverNotchSize}em)
              );
            }
          }
        `
      );
    }
  }}
`;
const Box = React.forwardRef(
  (
    {
      children,
      notchSizeEm,
      hasHover = false,
      background = 'transparent',
      ...rest
    },
    ref
  ) => {
    return (
      <Wrapper
        notchSizeEm={notchSizeEm}
        hasHover={hasHover}
        background={background}
        ref={ref}
        {...rest}
      >
        {children}
      </Wrapper>
    );
  }
);

export default Box;
