import { useState, useEffect } from 'react';
import { isBrowser, reduceMotion } from '../utils';

const useReducedMotion = (defaultVal = false) => {
  const [reducedMotion, setReducedMotion] = useState(defaultVal);

  // Callback for media query change events.
  function queryChangeHandler() {
    setReducedMotion(reduceMotion());
  }

  useEffect(() => {
    if (isBrowser() && 'matchMedia' in window) {
      const media = '(prefers-reduced-motion: no-preference)';

      // Grab the motion media query
      const mediaQuery = window.matchMedia(media);

      if (mediaQuery && mediaQuery.media === media) {
        setReducedMotion(!mediaQuery.matches);

        if (mediaQuery.addEventListener) {
          mediaQuery.addEventListener('change', queryChangeHandler);
        } else if (mediaQuery.addListener) {
          mediaQuery.addListener(queryChangeHandler);
        }

        return () => {
          if (mediaQuery.removeEventListener) {
            mediaQuery.removeEventListener('change', queryChangeHandler);
          } else if (mediaQuery.removeListener) {
            mediaQuery.removeListener(queryChangeHandler);
          }
        };
      }
    }
  }, []);

  return reducedMotion;
};

export default useReducedMotion;
