import React, { useCallback, useContext } from 'react';
import { LazyLoadContext } from '../context/lazyLoadedJs';

const AnchorLink = ({
  children,
  to,
  className,
  handleMouseEnter,
  handleMouseLeave,
  ariaLabel,
  ariaLabelledBy,
}) => {
  const [modules, moduleActions] = useContext(LazyLoadContext);
  const handleClick = (e) => {
    if (modules.gsap && modules.gsapScrollTo) {
      e.preventDefault();
      modules.gsap.to(window, {
        duration: 0.3,
        scrollTo: to,
        onComplete: () => {
          window.location = to;
        },
      });
    }
  };
  const onMouseEnter = useCallback(() => {
    if (handleMouseEnter) {
      handleMouseEnter(to);
    }
  }, [handleMouseEnter, to]);
  const onMouseLeave = useCallback(() => {
    if (handleMouseLeave) {
      handleMouseLeave(to);
    }
  }, [handleMouseLeave, to]);
  return (
    <a
      href={to}
      className={className}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};

export default AnchorLink;
