import React from 'react';

const UnderlinedSpan = React.forwardRef(
  ({ children, className, initiallyHidden = false, isActive = false }, ref) => {
    const classes = ['underline'];
    if (initiallyHidden) {
      classes.push('underline--hidden');
    }
    if (isActive) {
      classes.push('underline--active');
    }
    return (
      <span
        ref={ref}
        className={`${className ? `${className} ` : ''}${classes.join(' ')}`}
      >
        {children}
      </span>
    );
  }
);

export default UnderlinedSpan;
