import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import HeaderWrapper, { Header } from './Header';
import AnchorLink from './AnchorLink';
import UnderlinedSpan from './ui/UnderlinedSpan';
import { UNDERLINE_LINE_HEIGHT } from '../styles/variables';
import { bp } from '../styles/mediaBreakpoints';
import { isHeader } from '../utils';

const SectionHeader = React.forwardRef(
  ({ children, id, className, type, to }, ref) => {
    const headerContentRef = useRef();
    return (
      <HeaderWrapper
        className={className}
        id={id}
        as={Header}
        type={type}
        ref={ref}
      >
        <span className="section-header__content">
          <UnderlinedSpan active initiallyHidden ref={headerContentRef}>
            {children}
          </UnderlinedSpan>
        </span>
        {to && (
          <AnchorLink
            className="section-header__anchor"
            to={to}
            ariaLabelledBy={id}
            handleMouseEnter={() => {
              if (headerContentRef && headerContentRef.current) {
                headerContentRef.current.classList.add('underline--active');
              }
            }}
            handleMouseLeave={() => {
              if (headerContentRef && headerContentRef.current) {
                headerContentRef.current.classList.remove('underline--active');
              }
            }}
          >
            <FontAwesomeIcon icon={faLink} className={`${id}__icon`} />
          </AnchorLink>
        )}
      </HeaderWrapper>
    );
  }
);

const fontSize = 0.8;
const Wrapper = styled(SectionHeader)`
  overflow-wrap: break-word;

  .section-header__anchor {
    font-size: ${fontSize}em;
    line-height: ${UNDERLINE_LINE_HEIGHT / fontSize}em;
    transition: opacity 0.3s;
    opacity: 0.5;
    display: none;
    margin-right: 1em;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .section-header__content {
    max-width: 100%;
    display: inline-block;

    & > span {
      max-width: 100%;
    }
  }
  @media (min-width: ${bp.sm}px) {
    display: flex;
    .section-header__anchor {
      display: inline;
      order: -1;
    }
  }
  ${({ as, type }) => {
    if (isHeader(['h1', 'h2'], as, type)) {
      return css`
        .section-header__content {
          margin-left: 0;
        }
      `;
    }
  }}
`;

export default Wrapper;
