import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCode, faPenFancy } from '@fortawesome/free-solid-svg-icons';
import SvgMorph from '../SvgMorph';
import AnchorLink from '../AnchorLink';
import {
  UNDERLINE_DEGREE,
  colors,
  TOP_BAR_CONTENT_HEIGHT,
  TOP_BAR_BOTTOM_BORDER,
  SKEW_CLIP_PATH_SIZE_EM,
} from '../../styles/variables';
import { bp } from '../../styles/mediaBreakpoints';

import { LazyLoadContext } from '../../context/lazyLoadedJs';

const pathVariants = {
  user: [
    'M 320,0 c 3.07863,0 6.13183,0.10846216 9.15599,0.32178515 10.31724,0.72777415 20.29641,2.67602985 29.79367,5.70176575 3.03662,0.9674379 6.02397,2.045028 8.95735,3.2280961 46.96317,18.940811 80.09299,64.917433 80.09299,118.748353 0,70.70823 -57.29545,128 -128,128 -2.90687,0 -5.7911,-0.0969 -8.64962,-0.28752 -8.82263,-0.58846 -17.40045,-2.07052 -25.64371,-4.35651 -3.54445,-0.98294 -7.02704,-2.11451 -10.44065,-3.38758 -48.63449,-18.13776 -83.26602,-64.9988 -83.26602,-119.96839 -0.26287,-70.7917 57.30159,-128 128,-128 Z',
    'M 320.00133,304.00648 C 294.00133,304.00648 269.40133,298.20647 247.10133,288.00648 H 230.40133 C 156.20133,288.00648 96.001339,348.20647 96.001339,422.40647 V 464.00648 C 96.001339,490.50648 117.50134,512.00648 144.00133,512.00648 H 320.00133 V 304.00648 Z M 409.60133,288.00648 H 392.90133 C 370.70134,298.20647 346.00133,304.00648 320.00133,304.00648 V 512.00648 H 496.00133 C 522.50133,512.00648 544.00133,490.50648 544.00133,464.00648 V 422.40647 C 544.00133,348.20647 483.80133,288.00648 409.60133,288.00648 Z',
    'M 409.60133,288.00648 H 392.90133 C 370.70134,298.20647 346.00133,304.00648 320.00133,304.00648 V 512.00648 H 496.00133 C 522.50133,512.00648 544.00133,490.50648 544.00133,464.00648 V 422.40647 C 544.00133,348.20647 483.80133,288.00648 409.60133,288.00648 Z',
  ],
  code: [
    'M 346.22917,8.7 c 1.8,-6.4 8.5,-10 14.9,-8.2 l 61,17.7 c 6.4,1.8 10,8.5 8.2,14.9 l -64.6864,222.82451 -71.8136,247.37549 c -1.9,6.4 -8.5,10.1 -14.9,8.2 l -61,-17.7 c -6.4,-1.8 -10,-8.5 -8.2,-14.9 l 64.71317,-222.91674 Z',
    'M 164.92917,399.3 208.42917,352.9 C 213.02917,348 212.72917,340.2 207.62917,335.7 L 117.02917,256 207.62917,176.3 C 212.72917,171.8 213.12917,164 208.42917,159.1 L 164.92917,112.7 C 160.42917,107.9 152.82917,107.6 147.92917,112.2 L 3.8291603,247.2 C -1.2708398,251.9 -1.2708398,260 3.8291603,264.7 L 147.92917,399.8 C 152.82917,404.4 160.42917,404.2 164.92917,399.3 Z M 492.12917,399.9 636.22917,264.8 C 641.32917,260.1 641.32917,252 636.22917,247.3 L 492.12917,112.1 C 487.32917,107.6 479.72917,107.8 475.12917,112.6 L 431.62917,159 C 427.02917,163.9 427.32917,171.7 432.42917,176.2 L 523.02917,256 432.42917,335.7 C 427.32917,340.2 426.92917,348 431.62917,352.9 L 475.12917,399.3 C 479.62917,404.2 487.22917,404.4 492.12917,399.9 Z',
    'M 492.12917,399.9 636.22917,264.8 C 641.32917,260.1 641.32917,252 636.22917,247.3 L 492.12917,112.1 C 487.32917,107.6 479.72917,107.8 475.12917,112.6 L 431.62917,159 C 427.02917,163.9 427.32917,171.7 432.42917,176.2 L 523.02917,256 432.42917,335.7 C 427.32917,340.2 426.92917,348 431.62917,352.9 L 475.12917,399.3 C 479.62917,404.2 487.22917,404.4 492.12917,399.9 Z',
  ],
  pen: [
    'M 433.25,28.32 250.14,227.81 347.99,325.66 547.48,142.55 C 632.4,67.48 507.73,-55.94 433.25,28.32 Z',
    'M 143.18,282.94 C 133.62421,286.12572 126.12573,293.6242 122.94,303.18 L 64.000003,480 68.690003,484.69 161.58,391.8 C 160.92,389.24 160.01,386.77 160.01,384 160.01,366.33 174.34,352 192.01,352 200.83998,352 208.8359,355.57843 214.62637,361.36387 L 276.77489,299.39489 227.25,249.87 Z',
    'M 214.62637,361.36387 C 220.42343,367.1559 224.01,375.15998 224.01,384 224.01,401.67 209.68,416 192.01,416 189.24,416 186.77,415.09 184.21,414.43 L 91.319994,507.32 95.999994,512 272.82,453.06 C 282.37767,449.87731 289.87731,442.37767 293.06,432.82 L 326.13,348.75 276.77489,299.39489 Z',
  ],
  question: [
    'M 310.45519,373.459 C 272.25919,373.459 241.18419,404.534 241.18419,442.73 241.18419,480.925 272.25919,512 310.45519,512 348.65119,512 379.72619,480.925 379.72619,442.729 379.72619,404.533 348.65119,373.459 310.45519,373.459 Z',
    'M 381.51119,152.643 C 381.51119,175.195 362.89419,186.777 332.51819,203.807 297.09519,223.667 250.21919,248.383 250.21919,310.212 V 320 C 250.21919,333.255 260.96419,344 274.21919,344 H 346.69019 C 359.94519,344 370.69019,333.255 370.69019,320 V 314.227 C 370.69019,271.367 495.95819,269.582 495.95819,153.6 Z',
    'M 320.47619,0 C 240.65719,0 188.95819,32.703 148.36919,91.026 141.00619,101.606 143.27619,116.112 153.54719,123.9 L 196.68519,156.609 C 207.05819,164.474 221.81719,162.635 229.93819,152.461 254.98719,121.08 273.56819,103.012 312.69519,103.012 343.45919,103.012 381.51119,122.811 381.51119,152.643 L 495.95819,153.6 C 495.95919,66.256 405.35719,0 320.47619,0 Z',
  ],
};

const Wrapper = styled.nav`
  position: relative;
  height: calc(100vh - ${rem(TOP_BAR_CONTENT_HEIGHT + TOP_BAR_BOTTOM_BORDER)});
  height: ${({ theme }) => {
    return css`calc(
      var(--vh, 1vh) * 100 -
        ${rem(TOP_BAR_CONTENT_HEIGHT + TOP_BAR_BOTTOM_BORDER)}
    )`;
  }};

  overflow: hidden;
  @media (min-width: ${bp.md}px) {
    height: auto;
  }

  & > ul {
    height: 100%;
    padding: 0;
    margin: 0;

    & > li {
      list-style: none;
    }
  }

  .site-nav__anchor-text {
    margin-left: 2em;
    & > div {
      line-height: 1em;
      /* margin-bottom: 0.1em;
      padding-top: 0.1em; */

      &:first-child {
        margin-left: 1em;
      }
      &:last-child {
        margin-left: -1em;
      }
    }
    @media (min-width: ${bp.xs}px) {
      & > div {
        &:first-child {
          margin-left: 0.5em;
        }
        &:last-child {
          margin-left: -0.5em;
        }
      }
    }
  }

  .site-nav__anchor {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .site-nav__anchor-text {
      & > div {
        .underline {
          &:before {
            transition-delay: 0.15s;
            @media (prefers-reduced-motion: reduce) {
              transition-delay: 0s;
            }
          }
        }
        &:first-child {
          .underline {
            &:before {
              transition-delay: 0.3s;
              @media (prefers-reduced-motion: reduce) {
                transition-delay: 0s;
              }
            }
          }
        }
        &:last-child {
          .underline {
            &:before {
              transition-delay: 0s;
            }
          }
        }
      }
    }

    &:hover {
      .underline {
        &:before {
          transform: rotate(${UNDERLINE_DEGREE}deg) translateX(0%);
          @media (prefers-reduced-motion: reduce) {
            opacity: 1;
          }
        }
      }
      .site-nav__anchor-text {
        & > div {
          &:first-child {
            .underline {
              &:before {
                transition-delay: 0s;
              }
            }
          }
          &:last-child {
            .underline {
              &:before {
                transition-delay: 0.3s;
                @media (prefers-reduced-motion: reduce) {
                  transition-delay: 0s;
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: ${bp.md}px) {
      padding-top: 1.25em;
      padding-bottom: 1.25em;
      ${({ theme }) => {
        return theme.mixins.useSkewed(
          css`
            padding-bottom: 2em;
            padding-top: 2em;
          `
        );
      }}
    }
  }

  .site-nav__item {
    display: flex;
    justify-content: center;
    height: ${100 / 3}%;
    text-decoration: none;
    font-size: 1.5em;
    box-sizing: border-box;
    position: relative;
    text-transform: uppercase;
    z-index: 1;

    @media (min-width: ${bp.xs}px) {
      font-size: 2em;
    }
    @media (min-width: ${bp.md}px) {
      height: auto;
      border: none;

      margin-top: 0;
      ${({ theme }) => {
        return theme.mixins.useSkewed(
          css`
            margin-top: -2.5em;
          `
        );
      }}
    }
  }

  .site-nav__icon {
    position: absolute;
    font-size: 2em;
    top: 1em;
    left: 0.5em;
    transform: translateY(-50%);
    @media (min-width: ${bp.md}px) {
      display: none;
    }
  }

  .site-nav__item--about {
    background-color: ${({ theme }) => {
      return theme.colors.blue50;
    }};
    @media (min-width: ${bp.md}px) {
      margin-top: 0;
      padding-top: 1em;
      ${({ theme }) => {
        return theme.mixins.useSkewed(
          css`
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            margin-top: -2.5em;
          `
        );
      }}
      .site-nav__icon {
        left: auto;
        right: 25%;
      }
      .site-nav__anchor-text {
        margin-left: -6em;
      }
    }
    @media (min-width: ${bp.lg}px) {
      .site-nav__anchor-text {
        margin-left: -10em;
      }
    }
  }
  .site-nav__item--projects {
    background-color: ${({ theme }) => {
      return theme.colors.blue60;
    }};
    @media (min-width: ${bp.md}px) {
      ${({ theme }) => {
        return theme.mixins.useSkewed(
          css`
            clip-path: polygon(
              0 ${1.5 * SKEW_CLIP_PATH_SIZE_EM}em,
              100% 0,
              100% 100%,
              0 100%
            );
          `
        );
      }}
      .site-nav__icon {
        position: static;
        left: unset;
        top: unset;
        transform: translate(0%, 0%);
      }
      .site-nav__anchor {
        padding-bottom: 2em;
        padding-top: 1.5em;
      }
      .site-nav__anchor-text {
        margin-left: -4em;
      }
    }
    @media (min-width: ${bp.lg}px) {
      .site-nav__anchor-text {
        margin-left: -3em;
      }
    }
  }
  .site-nav__item--blog {
    background-color: ${({ theme }) => {
      return theme.colors.blue70;
    }};
    @media (min-width: ${bp.md}px) {
      ${({ theme }) => {
        return theme.mixins.useSkewed(
          css`
            clip-path: polygon(
              0 ${1.5 * SKEW_CLIP_PATH_SIZE_EM}em,
              100% 0,
              100% calc(100% - ${1.5 * SKEW_CLIP_PATH_SIZE_EM}em),
              0 100%
            );
          `
        );
      }}
      .site-nav__icon {
        left: 25%;
      }
      .site-nav__anchor {
        padding-bottom: 1.5em;
        padding-top: 1.5em;
      }
      .site-nav__anchor-text {
        margin-left: -2em;
      }
    }
    @media (min-width: ${bp.lg}px) {
      .site-nav__anchor-text {
        margin-left: 4em;
      }
    }
  }

  .site-nav__center-icon {
    position: absolute;
    top: 42%;
    right: -15em;
    transform: translate(0, -50%);
    width: 25em;
    height: 25em;
    border-radius: 50%;
    box-sizing: border-box;
    border: ${({ theme }) => {
      return `0.2em solid ${theme.colors.brown35}`;
    }};
    display: none;
    z-index: 3;
    background-color: white;
    ${({ theme }) => {
      return theme.mixins.patterns.texture(theme, theme.colors.blue30);
    }}
    svg {
      left: 22%;
      width: 30%;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: ${({ theme }) => {
        return `0.6em solid ${theme.colors.blue}`;
      }};
      box-sizing: border-box;
      border-radius: 50%;
    }

    @media (min-width: ${bp.md}px) {
      display: block;
    }
  }
`;

const SvgMorphStyled = styled(SvgMorph)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const SiteNavSection = ({ id }) => {
  const [modules, moduleActions] = useContext(LazyLoadContext);
  let changeIconVariant = () => {};

  const handleAnchorMouseLeave = () => {
    if (window.innerWidth < bp.md) return;
    changeIconVariant('question');
  };
  const handleAnchorMouseEnter = (href) => {
		if (window.innerWidth < bp.md) return;

		if (href === '#about') {
			changeIconVariant('user');
		} else if (href === '#projects') {
			changeIconVariant('code');
		}
	};

	return (
		<Wrapper id={id}>
			<h2 className="sr-only">Page contents</h2>
			<ul>
				<li className="site-nav__item site-nav__item--about">
					<AnchorLink
						to="#about"
						className="site-nav__anchor"
						handleMouseEnter={handleAnchorMouseEnter}
						handleMouseLeave={handleAnchorMouseLeave}
						ariaLabel="About me and my experience"
					>
						<FontAwesomeIcon className="site-nav__icon" icon={faUser} style={{ color: colors.blue }} />
						<div className="site-nav__anchor-text">
							<div>
								<span className="underline underline--hidden">
									about <span className="emphasize">me</span>
								</span>
							</div>
							<div className="font-center" style={{ marginLeft: '-2em' }}>
								<span className="underline underline--hidden">&amp;</span>
							</div>
							<div>
								<span className="underline underline--hidden">
									my <span className="emphasize">experience</span>
								</span>
							</div>
						</div>
					</AnchorLink>
				</li>
				<li className="site-nav__item site-nav__item--projects">
					<AnchorLink
						to="#projects"
						className="site-nav__anchor"
						handleMouseEnter={handleAnchorMouseEnter}
						handleMouseLeave={handleAnchorMouseLeave}
						ariaLabel="Projects built with love and passion"
					>
						<div className="site-nav__anchor-text">
							<div className="emphasize">
								<span className="underline underline--hidden">projects</span>
							</div>
							<div>
								<span className="underline underline--hidden">built with</span>
							</div>
							<div>
								<span className="emphasize">
									<span className="underline underline--hidden">love &amp; passion</span>
								</span>
							</div>
						</div>
						<FontAwesomeIcon className="site-nav__icon" icon={faCode} style={{ color: colors.blue }} />
					</AnchorLink>
				</li>
			</ul>
			<div className="site-nav__center-icon">
				<SvgMorphStyled
					kutejs={modules.kutejs}
					pathVariants={pathVariants}
					changeVariant={(handler) => {
						return (changeIconVariant = handler);
					}}
					viewBox="0 0 640 512"
					fill={colors.blue}
					initialVariant="question"
				/>
			</div>
		</Wrapper>
	);
};

export default SiteNavSection;
