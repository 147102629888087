import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faCode,
  faMitten,
} from '@fortawesome/free-solid-svg-icons';
import {
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faReact,
  faGitAlt,
  faSass,
  faGulp,
} from '@fortawesome/free-brands-svg-icons';
import { SiAdobephotoshop } from '@react-icons/all-files/si/SiAdobephotoshop';
import { SiWebpack } from '@react-icons/all-files/si/SiWebpack';
import { SiGatsby } from '@react-icons/all-files/si/SiGatsby';
import { SiTypescript } from '@react-icons/all-files/si/SiTypescript';
import SectionHeader from '../SectionHeader';
import SocialNav from '../SocialNav';
import Button from '../ui/Button';
import Box from '../ui/Box';
import Card from '../ui/Card';
import useRevealAnimation from '../../hooks/useRevealAnimation';
import {
  BUTTON_BORDER_WIDTH,
  BUTTON_DEGREE,
  colors,
} from '../../styles/variables';
import { bp } from '../../styles/mediaBreakpoints';
import useSiteMetadata from '../../hooks/useSiteMetadata';

const Wrapper = styled.section`
  ${({ theme, id }) => {
    return css`
      & > ul {
        padding: 0;
        margin: 0;
      }
      & > ul > li {
        list-style: none;
      }
      .${id}__contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin: 2em;
      }
      .${id}__contact-nav {
        display: inline-block;
        border-left: ${BUTTON_BORDER_WIDTH}px solid ${theme.colors.blue};
        border-right: ${BUTTON_BORDER_WIDTH}px solid ${theme.colors.blue};
        margin-top: 2em;
      }
      .${id}__contact-nav_content {
        margin: 0;
        padding-top: 0.2em;
      }
      @media (min-width: ${bp.sm}px) {
        .${id}__contact {
          flex-direction: row;
        }
        .${id}__contact-nav {
          margin-left: 2em;
          margin-top: 0em;
        }
      }
      ${theme.mixins.useSkewed(
        css`
          .${id}__contact-nav {
            transform: ${theme.mixins.skew()};
          }
          .${id}__contact-nav_content {
            transform: ${theme.mixins.unskew()};
          }
        `
      )}

      .${id}-skills__wrapper {
        display: flex;
        justify-content: space-evenly;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
        @media (min-width: ${bp.lg}px) {
          width: 85%;
        }
        @supports (display: grid) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          align-items: stretch;
          justify-content: center;
          grid-row-gap: 1em;
          row-gap: 1em;
          grid-column-gap: 1em;
          column-gap: 1em;
          @media (min-width: ${bp.sm}px) {
            width: 85%;
            grid-row-gap: 1.5em;
            row-gap: 1.5em;
            grid-column-gap: 1.5em;
            column-gap: 1.5em;
          }
          @media (min-width: ${bp.md}px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          }
        }
      }
      .${id}-skills__item {
        padding: 0.75em 1.5em;
        line-height: 1.15;
        color: white;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 1em 1em;
        cursor: default;
        min-width: 3em;
        width: 80%;

        &.${id}-skills__item--with-text {
          .${id}-skills__skill-icon {
            margin-bottom: 0.2em;
          }
        }
        @media (min-width: ${bp.xs}px) {
          width: 28%;
        }
        @media (min-width: ${bp.sm}px) {
          padding: 0.5em 1em;
          width: 30%;
        }
        @media (min-width: ${bp.md}px) {
          width: 22%;
        }
        @media (min-width: ${bp.lg}px) {
          width: 20%;
          margin: 0 0.4em 1em;
        }
        @media (min-width: ${bp.xl}px) {
          width: 17%;
        }
        @supports (display: grid) {
          width: auto;
          margin: 0;
          grid-column-start: auto;
          grid-column-end: span 2;
          &:last-child {
            grid-column-start: 2;
            grid-column-end: span 2;
          }
          @media (min-width: ${bp.md}px) {
            &:last-child {
              grid-column-start: 3;
            }
          }
        }
      }
      .${id}-skills__skill-icon {
        font-size: 1.5em;
        max-width: unset;
      }
      img.${id}-skills__skill-icon {
        font-size: 1em;
        height: 1.5em;
        width: auto;
      }
      .${id}-skills__skill-text {
      }

      .${id}-experience__list, .${id}-education__list, .${id}-projects__list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
      }
    `;
  }}
`;

const SocialNavHeader = React.createElement(
  'h3',
  { className: 'sr-only' },
  'Mail and social links'
);

const skills = [
  {
    text: 'React',
    icon: faReact,
  },
  {
    text: 'JavaScript',
    icon: faJsSquare,
  },
  {
    text: 'CSS',
    icon: faCss3Alt,
  },
  {
    text: 'Sass',
    icon: faSass,
  },
  {
    text: 'HTML',
    icon: faHtml5,
  },
  {
    text: 'GreenSock',
    icon: 'gsap.svg',
    imgFile: true,
    width: 250,
    height: 291,
  },
  {
    text: 'Git',
    icon: faGitAlt,
  },
  {
    text: 'TypeScript',
    icon: SiTypescript,
    reactIcons: true,
  },
  {
    text: 'Webpack',
    icon: SiWebpack,
    reactIcons: true,
  },
  {
    text: 'Gulp',
    icon: faGulp,
  },
  {
    icon: 'phaser.png',
    imgFile: true,
    ariaLabel: 'Phaser',
    width: 104,
    height: 22,
  },
  {
    text: 'GatsbyJS',
    icon: SiGatsby,
    reactIcons: true,
  },
  {
    text: 'Photoshop',
    icon: SiAdobephotoshop,
    reactIcons: true,
  },
];

const AboutMeSection = ({ id, wasVisited }) => {
  const siteMetadata = useSiteMetadata();

  const fadeInProps = {
    duration: 0.5,
    autoAlpha: 1,
  };
  const fadeInInitProps = {
    opacity: '0',
    transform: 'translate(0, 0)',
  };
  const slideInProps = {
    duration: 0.5,
    autoAlpha: 1,
    xPercent: 0,
    x: 0,
  };
  const slideInInitProps = {
    opacity: '0',
    transform: 'translateX(-50%)',
  };

  const sh1 = useRef();
  const [setSh1, inViewRef, inView, entry] = useRevealAnimation(
    sh1,
    [
      {
        targets: [sh1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh1P1 = useRef();
  const sh1P2 = useRef();
  const sh1P3 = useRef();
  const sh1P4 = useRef();
  const sh1P5 = useRef();
  const [setSh1P1] = useRevealAnimation(
    sh1P1,
    [
      {
        targets: [sh1P1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );
  const [setSh1P2] = useRevealAnimation(
    sh1P2,
    [
      {
        targets: [sh1P2],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );
  const [setSh1P3] = useRevealAnimation(
    sh1P3,
    [
      {
        targets: [sh1P3],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );
  const [setSh1P4] = useRevealAnimation(
    sh1P4,
    [
      {
        targets: [sh1P4],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );
  const [setSh1P5] = useRevealAnimation(
    sh1P5,
    [
      {
        targets: [sh1P5],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const contactRef = useRef();
  const cvRef = useRef();
  const asideNavRef = useRef();
  const asideNavContentRef = useRef();

  const [setRefCv] = useRevealAnimation(
    contactRef,
    [
      {
        targets: [cvRef],
        animationProps: {
          duration: 0.5,
          x: '0%',
          xPercent: 0,
          skewX: `${BUTTON_DEGREE}deg`,
          autoAlpha: 1,
        },
        initialStyle: {
          opacity: 0,
          transform: `translate(-50%, 0)`,
        },
      },
      {
        targets: [asideNavRef],
        animationProps: {
          duration: 0.1,
          opacity: 1,
        },
        initialStyle: {
          opacity: 0,
        },
      },
      {
        targets: [asideNavRef],
        animationProps: {
          duration: 0.5,
          skewX: `${BUTTON_DEGREE}deg`,
          scaleX: 1,
        },
        initialStyle: {
          transform: `scaleX(0) skewX(${BUTTON_DEGREE}deg)`,
        },
      },
      {
        targets: [asideNavContentRef],
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          delay: 1,
        },
        initialStyle: {
          opacity: '0',
          transform: `translate(0, 0) skewX(${-BUTTON_DEGREE}deg)`,
        },
      },
    ],
    wasVisited
  );

  const sh2 = useRef();
  const [setSh2] = useRevealAnimation(
    sh2,
    [
      {
        targets: [sh2],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh2P1 = useRef();
  const [setSh2P1] = useRevealAnimation(
    sh2P1,
    [
      {
        targets: [sh2P1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const skillWrapperRef = useRef();
  const skillRefArray = useRef([]);
  skillRefArray.current = skills.map(() => {
    return React.createRef();
  });
  const [setRefsSkillWrapper] = useRevealAnimation(
    skillWrapperRef,
    [
      {
        targets: skillRefArray,
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1,
          rotation: '0deg',
          stagger: { each: 0.2, from: 'center', grid: 'auto' },
          ease: 'elastic.out(1, 0.5)',
          delay: 1,
        },
        initialStyle: {
          opacity: '0',
          transform: 'rotate(-10deg) scale(0.5)',
        },
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh3 = useRef();
  const [setSh3] = useRevealAnimation(
    sh3,
    [
      {
        targets: [sh3],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh3P1 = useRef();
  const [setSh3P1] = useRevealAnimation(
    sh3P1,
    [
      {
        targets: [sh3P1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh3P2 = useRef();
  const [setSh3P2] = useRevealAnimation(
    sh3P2,
    [
      {
        targets: [sh3P2],
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1,
          ease: 'elastic.out(1, 0.5)',
        },
        initialStyle: {
          opacity: 0,
          transform: 'scale(0.8)',
        },
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh4 = useRef();
  const [setSh4] = useRevealAnimation(
    sh4,
    [
      {
        targets: [sh4],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh4P1 = useRef();
  const [setSh4P1] = useRevealAnimation(
    sh4P1,
    [
      {
        targets: [sh4P1],
        animationProps: slideInProps,
        initialStyle: slideInInitProps,
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  const sh4P2 = useRef();
  const [setSh4P2] = useRevealAnimation(
    sh4P2,
    [
      {
        targets: [sh4P2],
        animationProps: {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1,
          ease: 'elastic.out(1, 0.5)',
        },
        initialStyle: {
          opacity: 0,
          transform: 'scale(0.8)',
        },
        reduced: {
          animationProps: fadeInProps,
          initialStyle: fadeInInitProps,
        },
      },
    ],
    wasVisited
  );

  return (
		<Wrapper className="container horizontal-pad" id={id}>
			<SectionHeader type="h2" id={`${id}__header`} to={`#${id}`} ref={setSh1}>
				About me
			</SectionHeader>
			<p className="text-content" ref={setSh1P1}>
				Hey, I'm <span className="underline">Robert</span> <FA icon={faMitten} />. I'm an experienced{' '}
				<em>front-end developer</em> <FA icon={faCode} /> from Poland. Technology is my life's main interest. I've been
				very fortunate to be able to translate that passion into my full-time profession since 2018.
			</p>
			<p className="text-content" ref={setSh1P2}>
				My focus from the beginning has been the front-end part of web programming. I love that my work is directly
				exposed to the user, which makes it especially meaningful in my opinion. Website's visitors and their experience
				with using the site are very important to me. That's why, I love implementing beautiful <em>animations</em>{' '}
				enriching <em>user interface</em> which is <em>accessible</em> to everyone.
			</p>
			<p className="text-content" ref={setSh1P3}>
				My current goal is to constantly learn and <em>master</em> the core front-end stack (
				<FA icon={faJsSquare} /> JavaScript, <FA icon={faCss3Alt} /> CSS, and semantic <FA icon={faHtml5} /> HTML) as
				well as modern and advanced tools like <FA icon={faReact} /> <em>React</em>.
			</p>
			<SectionHeader type="h3" id={`${id}-skills`} to={`#${id}-skills`} ref={setSh2}>
				Skills
			</SectionHeader>
			<p className="text-content section-description" ref={setSh2P1}>
				A few notable technologies and tools I'm experienced with:
			</p>
			<ul className={`${id}-skills__wrapper`} ref={setRefsSkillWrapper}>
				{skills.map((data, index) => {
					let icon;
					let imgAlt = '';
					if (data.imgAlt) {
						imgAlt = data.imgAlt;
					}
					if (data.icon) {
						if (data.reactIcons) {
							icon = (
								<data.icon
									aria-hidden="true"
									focusable="false"
									className={`${id}-skills__skill-icon react-icons-icon`}
								/>
							);
						} else if (data.imgFile) {
							icon = (
								<img
									alt={imgAlt}
									src={data.icon}
									className={`${id}-skills__skill-icon`}
									width={data.width}
									height={data.height}
								/>
							);
						} else {
							icon = <FA icon={data.icon} className={`${id}-skills__skill-icon`} />;
						}
					}
					const classes = [`${id}-skills__item`];
					if (data.text) {
						classes.push(`${id}-skills__item--with-text`);
					}
					return (
						<Box
							className={classes.join(' ')}
							background={colors.blue}
							notchSizeEm={0.5}
							key={`skill-${index}`}
							as="li"
							ref={skillRefArray.current[index]}
						>
							{icon}
							{data.text && <span className={`${id}-skills__skill-text`}>{data.text}</span>}
							{!data.text && data.ariaLabel && <span className="sr-only">{data.ariaLabel}</span>}
						</Box>
					);
				})}
			</ul>
			<SectionHeader type="h3" id={`${id}-experience`} to={`#${id}-experience`} ref={setSh3}>
				Experience
			</SectionHeader>
			<p className="text-content section-description" ref={setSh3P1}>
				Companies I was or currently am working for:
			</p>
			<ul className={`${id}-experience__list`} ref={setSh3P2}>
				<Card
					className={`${id}-education__item`}
					as="li"
					hasShadow
					titleProps={{
						children: 'Sarigato Group',
					}}
					infoProps={{
						children: (
							<>
								<span className={`${id}-experience__item__time-frame__from`}>July 2018</span>
								{' - '}
								<span className={`${id}-experience__item__time-frame__to`}>now</span>
							</>
						),
					}}
				>
					<ul>
						<li>taking part in creating engaging websites</li>
						<li>making complex and dynamic Rich Media ad creatives seen nation-wide</li>
						<li>developing new types of ad creatives</li>
					</ul>
				</Card>
			</ul>
			<SectionHeader type="h3" id={`${id}-education`} to={`#${id}-education`} ref={setSh4}>
				Education
			</SectionHeader>
			<p className="text-content section-description" ref={setSh4P1}>
				My university degree:
			</p>
			<ul className={`${id}-education__list`} ref={setSh4P2}>
				<Card
					className={`${id}-education__item`}
					as="li"
					hasShadow
					titleProps={{
						children: 'Warsaw University',
					}}
					infoProps={{
						children: 'Field: Biotechnology',
					}}
				>
					<p>Degree: Bachelor</p>
					<p>Year of graduation: 2012</p>
				</Card>
			</ul>
		</Wrapper>
	);
};

export default AboutMeSection;
