import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { SiteNavSection, AboutSection, ProjectsSection } from '../components/mainPage';
import { LazyLoadContext } from '../context/lazyLoadedJs';
import { PageVisitedContext } from '../context/pageVisited';

const IndexPage = ({ data, location }) => {
	const [modules, moduleActions] = useContext(LazyLoadContext);
	const [pageVisited, pageVisitedActions] = useContext(PageVisitedContext);

	const encoded = encodeURIComponent(location.pathname);
	let wasVisited = false;
	if (pageVisited && pageVisited[encoded]) {
		wasVisited = true;
	}

	useEffect(() => {
		moduleActions.load(['gsap', 'gsapScrollTo']).finally(() => {});
		moduleActions.load(['kutejs']);
	}, []);
	useEffect(() => {
		if (modules.gsap && modules.gsapScrollTo) {
			modules.gsap.registerPlugin(modules.gsapScrollTo);
		}
	}, [modules, modules.gsap, modules.gsapScrollTo]);

	return (
		<Layout location={location}>
			<SEO location={location} titlePrefix="Portfolio" image="header_index.png" />
			<main>
				<SiteNavSection id="site-nav" />
				<AboutSection id="about" wasVisited={wasVisited} />
				<ProjectsSection
					id="projects"
					location={location}
					data={{ images: { portfolio: data.portfolioImage } }}
					wasVisited={wasVisited}
				/>
			</main>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query {
		portfolioImage: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "main_page/portfolio.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
