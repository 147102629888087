import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useWhatChanged } from '@simbathesailor/use-what-changed';
import { LazyLoadContext } from '../context/lazyLoadedJs';
import { isBrowser, isArray } from '../utils';
import useReducedMotion from './useReducedMotion';

const animate = (
  gsap,
  targetRefs,
  { type = 'to', delay = 0.5, ...animationArgs } = {}
) => {
  const targets = targetRefs.current ? targetRefs.current : targetRefs;
  gsap[type](
    targets.map((targetRef) => {
      return targetRef.current;
    }),
    { ...animationArgs }
  ).delay(delay);
};

const setInitialStyle = (targetRefs, styleArgs = {}) => {
  const targets = targetRefs.current ? targetRefs.current : targetRefs;
  targets.forEach((targetRef) => {
    if (targetRef.current) {
      Object.assign(targetRef.current.style, styleArgs);
    }
  });
};

const useRevealAnimation = (
  triggerRef,
  animationData = [],
  block,
  inViewOptions = {
    triggerOnce: true,
    rootMargin: '0px 0px',
  }
) => {
  const [wasRevealed, setWasRevealed] = useState(false);
  const [modules, moduleActions] = useContext(LazyLoadContext);
  const [inViewRef, inView, entry] = useInView(inViewOptions);
  const animData = useRef(animationData);
  const reducedMotion = useReducedMotion();

  // console.log(
  //   `%c useRevealAnimation`,
  //   'background-color: #000000; color: #ffffff; padding: 3px 8px 3px 8px;'
  // );

  // useWhatChanged([
  //   triggerRef,
  //   inViewRef,
  //   modules.gsap,
  //   wasRevealed,
  //   targetsRef,
  //   inView,
  // ]);

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      triggerRef.current = node;
      if (isBrowser() && 'IntersectionObserver' in window && !block) {
        // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
        inViewRef(node);
      }
    },
    [triggerRef, inViewRef, block]
  );

  useEffect(() => {
    if (isBrowser() && 'IntersectionObserver' in window && !block) {
      if (
        !wasRevealed &&
        modules.gsap &&
        animData.current &&
        isArray(animData.current)
      ) {
        animData.current.forEach((data, index) => {
          setInitialStyle(
            data.targets.current
              ? animationData[index].targets.current
              : data.targets,
            reducedMotion && data.reduced && data.reduced.initialStyle
              ? data.reduced.initialStyle
              : data.initialStyle
          );
        });
      }
    }
  }, [modules.gsap, wasRevealed, animationData, reducedMotion, block]);

  useEffect(() => {
    if (inView) {
      if (!wasRevealed) {
        if (modules.gsap && animData.current && isArray(animData.current)) {
          animData.current.forEach((data) => {
            animate(
              modules.gsap,
              data.targets,
              reducedMotion && data.reduced && data.reduced.animationProps
                ? data.reduced.animationProps
                : data.animationProps
            );
          });
        }
        setWasRevealed(true);
      }
    }
  }, [inView, modules.gsap, wasRevealed, reducedMotion]);

  return [setRefs, inViewRef, inView, entry];
};

export default useRevealAnimation;
